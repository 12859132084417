export const ImageSizeSelector = ({ handleChange, name, sizeObj }) => {
  return (
    <>
      <label
        id="demo-radio-buttons-group-label"
        className="font-body text-sm font-normal text-sub mr-9"
      >
        Image size
      </label>
      {sizeObj.map((size, index) => {
        index += 1;
        return (
          <div key={index} className="form-check form-check-inline mr-5">
            <input
              className="form-check-input form-check-input rounded-full h-4 w-4 text-gogreen bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 float-left mr-2 mt-1 align-top cursor-pointer"
              type="radio"
              name={name}
              id={`inlineRadio${index}`}
              value={`overlay${index}`}
              defaultChecked={size.default}
              onChange={handleChange}
            />
            <label
              className="form-check-label inline-block font-body text-sm font-normal text-sub"
              htmlFor={`inlineRadio${index}`}
            >
              {size.sizeLabel}
            </label>
          </div>
        );
      })}
    </>
  );
};
