import React from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Dropdown, Radio } from "flowbite-react";

import { LanguagePanel } from "../../../layouts/imgGenerator/language-panel";
import {
  DetectLanguage,
  TranslateInput,
} from "../../../services/firebaseActions/language";
import { LanguageFont } from "../../../utilities/utilityService";
import Generated from "./generated";
import {
  imageSizes,
  youtubeimages,
} from "../../../utilities/imgGenerator/ytObjs";
import { ImageSizeSelector } from "../../../layouts/imageSizeSelector";

const initialState = {
  id: 1,
  name: "Default",
  image: "/lines-brand.jpg",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function YoutubeImgDisplay(props) {
  const [selected, setSelected] = useState(youtubeimages[0]);
  const content = props.data.content;

  //translator
  const translateTop = TranslateInput(content, "en", props.data.lang);
  //detector
  const detectLang = DetectLanguage(content);
  //font
  const { fontStyle } = LanguageFont(detectLang);

  return (
    <div>
      <div className="youtube container mx-auto px-2 sm:px-4 lg:px-12 xl:max-w-full 2xl:max-w-screen-2xl">
        <div className="grid grid-cols-12 sm:px-4">
          <div className="sm:col-span-8 col-span-12">
            <div className="formselect">
              <select
                onChange={props.handleChange}
                className="w-96 mb-12 text-main font-display text-2xl font-bold border-transparent cursor-pointer pl-0 focus:border-gogreen focus:ring-gogreen"
                id="demo-simple-select"
                label="Select Image Creator Template"
                name="form"
              >
                <option disabled={true} value="">
                  Select Image Creator Template
                </option>
                <option value="template1">Thumbnail</option>
              </select>
            </div>
          </div>
          {/*  <div className="hidden sm:flex sm:justify-end col-span-12 sm:col-span-4">
            <TutorialModal url={"./youtube-thumb-tut.mp4"} />
          </div> */}
        </div>
        <div className="grid grid-cols-12 gap-8 sm:px-4">
          <div className="col-span-12 sm:col-span-12 lg:col-span-5">
            <p className="mb-6">
              Create and customise your image by using the fields below.
            </p>
            <form>
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Settings
              </h3>
              <div className="image-settings">
                <div className="flex justify-left">
                  <ImageSizeSelector
                    handleChange={props.handleChange}
                    sizeObj={imageSizes}
                    name="ecoOverlay"
                  />
                </div>
                <div className="input-group my-8">
                  <div className="form-group">
                    <Listbox
                      value={selected}
                      onChange={(e) => {
                        setSelected(e);
                        props.handleSelect(e);
                      }}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="font-body text-sm font-normal text-sub text-muted mb-3">
                            Select Key Visual
                          </Listbox.Label>
                          <div className="relative mt-1">
                            <Listbox.Button className="relative w-full cursor-pointer border border-border bg-white py-2 pl-3 pr-10 text-left focus:border-gogreen focus:outline-none focus:ring-1 focus:ring-gogreen sm:text-sm">
                              <span className="flex items-center cursor-pointer">
                                <img
                                  src={selected.image}
                                  alt=""
                                  className="h-6 w-6 flex-shrink-0"
                                />
                                <span className="ml-3 block truncate text-sm">
                                  {selected.name}
                                </span>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="grid grid-cols-4 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {[...youtubeimages]
                                  .reverse()
                                  .map((imageitem) => (
                                    <Listbox.Option
                                      key={imageitem.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? "text-white" : "text-sub",
                                          "relative cursor-pointer select-none py-2 pl-3 pr-9 w-full"
                                        )
                                      }
                                      value={imageitem}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className="block">
                                            <img
                                              src={imageitem.image}
                                              alt=""
                                              className="h-20 w-auto flex-shrink-0"
                                            />
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "text-sub text-xs mt-2 block truncate"
                                              )}
                                            >
                                              {imageitem.name}
                                            </span>
                                          </div>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-gogreen"
                                                  : "text-gogreen",
                                                "absolute inset-y-0 top-3 left-4 flex items-top pr-4 z-50"
                                              )}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                              </svg>
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>
                <p className="font-body text-sm font-normal text-sub text-muted mb-3">
                  Or Upload Key Visual{" "}
                  <span className="italic text-xs">
                    (1280 x 720 for best results.)
                  </span>
                </p>
                <div className="input-group">
                  <div className="custom-file relative">
                    <div className="max-w-xl">
                      <label className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                        <span className="flex items-center space-x-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-gray-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            />
                          </svg>
                          <span className="font-body text-sm font-normal text-sub">
                            Drop files to Attach, or
                            <span className="font-body text-sm font-normal  text-gogreen underline">
                              {" "}
                              browse
                            </span>
                          </span>
                        </span>
                        <input
                          onChange={props.handleChange}
                          type="file"
                          className="custom-file-input block w-full font-body text-sm text-sub cursor-pointer absolute h-full color-transparent -indent-56 focus:border-gogreen focus:ring-gogreen"
                          id="inputGroupFile04"
                          aria-describedby="inputGroupFileAddon04"
                          accept="image/x-png,image/gif,image/jpeg"
                          name="randomImg"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Details
              </h3>
              <div className="image-details">
                <div className="input-group">
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Title
                  </label>
                  <textarea
                    className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    type="text"
                    rows="4"
                    name="content"
                    placeholder="Enter your thumbnail title"
                    value={props.data.content}
                    onChange={props.handleChange}
                  />
                </div>
                <div className="colorPicker mb-6">
                  <Dropdown label="Select Colour">
                    <fieldset
                      className="flex justify-start align-middle"
                      id="radio"
                      name="subTextColor"
                      onChange={props.handleChange}
                    >
                      <div className="grid grid-cols-5 gap-1">
                        <div>
                          <Radio
                            id="dark"
                            name="subTextColor"
                            value="dark"
                            defaultChecked={true}
                          />
                        </div>
                        <div>
                          <Radio
                            id="orange"
                            name="subTextColor"
                            value="customRed"
                          />
                        </div>
                        <div>
                          <Radio
                            id="green"
                            name="subTextColor"
                            value="gogreen"
                          />
                        </div>
                        <div>
                          <Radio
                            id="grey"
                            name="subTextColor"
                            value="checkboxGrey"
                          />
                        </div>
                      </div>
                    </fieldset>
                  </Dropdown>
                </div>
              </div>
              <LanguagePanel
                topText={content}
                translateTop={translateTop}
                handleLanguage={props.handleChange}
                topTextPlaceHolder="Enter your thumbnail title"
              />
            </form>
          </div>
          <div className="col-span-7 sm:pl-0 lg:pl-11">
            <div className="img-preview-wrap inline-block w-full pb-24">
              <div
                id="gen-img-preview"
                className={`DisplayImg DisplayImgPreview hidden sm:hidden lg:block`}
              >
                <img
                  src="/Logo-Green-rgb.png"
                  className="img-fluid absolute z-20 top-4 right-4"
                  alt=""
                  width="60px"
                />
                <div className="grid grid-cols-[auto_345px]">
                  {content ? (
                    <div
                      className={`${props.data.subTextColor}  content min-h-full h-[360px] p-6 flex items-end`}
                    >
                      <h2
                        className={`text-white font-goma text-[40px] leading-[48px] ${fontStyle}`}
                      >
                        {props.data.content}
                      </h2>
                    </div>
                  ) : (
                    <div
                      className={`${props.data.subTextColor}  content min-h-full h-[360px] p-6 flex items-end`}
                    >
                      <h2
                        className={`text-white font-goma text-[40px] leading-[48px] ${fontStyle}`}
                      >
                        Enter your thumbnail title
                      </h2>
                    </div>
                  )}
                  <div className="block h-[360px] min-h-full">
                    <img
                      src={props.data.randomImg}
                      className="img-fluid img-thumbnail"
                      alt=""
                      width="100%"
                    />
                  </div>
                </div>
              </div>
              {/* Generated component */}
              <Generated
                content={content}
                subTextColor={props.data.subTextColor}
                fontStyle={fontStyle}
                dataContent={props.data.content}
                randomImg={props.data.randomImg}
              />
              {/* End of generated component */}
              <div className="flex w-max">
                <button
                  type="button"
                  className="btn mt-11 rounded"
                  onClick={(e) => {
                    setSelected(initialState);
                    props.handleFormReset(e);
                  }}
                >
                  Reset Preview
                </button>
                <button
                  type="button"
                  className="btn btn-primary ease-linear transition-all duration-150 mt-11 rounded"
                  onClick={props.handleJpeg}
                >
                  Generate Image
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-12">
            <div className="screenshot relative">
              <h3 className="mb-2.5 font-display font-bold">
                What it will look like
              </h3>
              <img
                src="/channel-preview-youtubethumb.jpg"
                className="img-fluid"
                alt=""
                width="100%"
                height="auto"
              />
              <div className="thumbnail-screenshot-wrapper absolute bottom-[18px] left-[51px]">
                <div
                  id="gen-img-preview"
                  className={`DisplayImg DisplayImgPreview hidden sm:hidden lg:block`}
                >
                  <img
                    src="/Logo-Green-rgb.png"
                    className="img-fluid absolute z-20 top-2 right-1"
                    alt=""
                    width="20px"
                  />
                  <div className="grid grid-cols-[auto_111px]">
                    {content ? (
                      <div
                        className={`${props.data.subTextColor} content min-h-full h-[121px] p-3 flex items-end`}
                      >
                        <h2 className="text-white font-goma text-[11px] leading-[13px]">
                          {props.data.content}
                        </h2>
                      </div>
                    ) : (
                      <div
                        className={`${props.data.subTextColor} content min-h-full h-[121px] p-3 flex items-end`}
                      >
                        <h2 className="text-white font-goma text-[11px] leading-[13px]">
                          Enter your thumbnail title
                        </h2>
                      </div>
                    )}
                    <div className="">
                      <img
                        src={props.data.randomImg}
                        className="img-fluid img-thumbnail"
                        alt=""
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
                <div className="content-below-screenshot w-[215px]">
                  <p className="font-body text-base font-bold leading-[1.1rem] mt-[10px] mb-[6px]">
                    This is where your other title goes
                  </p>
                  <span className="text-[#606060] font-body  text-xs font-bold">
                    447 views
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YoutubeImgDisplay;
