import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { DetectLanguage } from "../../../services/firebaseActions/language";
import { LanguageFont } from "../../../utilities/utilityService";
import { images } from "../../../utilities/imgGenerator/newsAnalysisObj";
import {
  imageSizes,
  titles,
} from "../../../utilities/imgGenerator/financialObj";
import { ImageSizeSelector } from "../../../layouts/imageSizeSelector";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Financial(props) {
  const [selected, setSelected] = useState();
  const { financial_topText, financialOverlay, financialImg, financialAuthor } =
    props.data;
  const [author, setAuthor] = useState({});

  //set author
  useEffect(() => {
    const foundAuthor = titles.find((author) => financialAuthor === author.id);
    if (foundAuthor) {
      setAuthor(foundAuthor);
    }
  }, [financialAuthor]);

  //detector
  const detectLang = DetectLanguage(financial_topText);
  //font
  const { fontStyle, btmFontStyle } = LanguageFont(detectLang);

  return (
    <div>
      <div className="grid grid-cols-12 gap-8 sm:px-4">
        <div className="col-span-12 sm:col-span-12 lg:col-span-5">
          <p className="mb-6">
            Create and customise your image by using the fields below.
          </p>

          <form>
            <div className="image-details">
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Settings
              </h3>

              <div className="image-settings">
                <div className="flex justify-left">
                  <ImageSizeSelector
                    handleChange={props.handleChange}
                    sizeObj={imageSizes}
                    name="financialOverlay"
                  />
                </div>
                <div className="input-group my-8">
                  <div className="form-group">
                    <Listbox
                      value={selected}
                      onChange={(e) => {
                        setSelected(e);
                        props.handleSelectFinancial(e);
                      }}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="font-body text-sm font-normal text-sub text-muted mb-3">
                            Select Key Visual
                          </Listbox.Label>
                          <div className="relative mt-1">
                            <Listbox.Button className="relative w-full cursor-pointer border border-border bg-white py-2 pl-3 pr-10 text-left focus:border-gogreen focus:outline-none focus:ring-1 focus:ring-gogreen sm:text-sm">
                              <span className="flex items-center cursor-pointer">
                                {selected ? (
                                  <img
                                    src={selected.image}
                                    alt=""
                                    className="h-6 w-6 flex-shrink-0"
                                  />
                                ) : (
                                  "Select Campaign Key Visual"
                                )}
                                <span className="ml-3 block truncate text-sm">
                                  {selected && selected.name}
                                </span>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="grid grid-cols-4 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {[...images].reverse().map((imageitem) => (
                                  <Listbox.Option
                                    key={imageitem.id}
                                    className={({ active }) =>
                                      classNames(
                                        active ? "text-white" : "text-sub",
                                        "relative cursor-pointer select-none py-2 pl-3 pr-9 w-full"
                                      )
                                    }
                                    value={imageitem}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <div className="block">
                                          <img
                                            src={imageitem.image}
                                            alt=""
                                            className="h-20 w-auto flex-shrink-0"
                                          />
                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "text-sub text-xs mt-2 block truncate"
                                            )}
                                          >
                                            {imageitem.name}
                                          </span>
                                        </div>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-gogreen"
                                                : "text-gogreen",
                                              "absolute inset-y-0 top-3 left-4 flex items-top pr-4 z-50"
                                            )}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="currentColor"
                                              className="w-6 h-6"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                              />
                                            </svg>
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>
                <p className="font-body text-sm font-normal text-sub text-muted mb-3">
                  Or Upload Key Visual{" "}
                  <span className="italic text-xs">
                    (1920px x 1080px for best results.)
                  </span>
                </p>
                <div className="input-group">
                  <div className="custom-file relative">
                    <div className="max-w-xl">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col justify-center items-center w-full h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="mb-3 w-10 h-10 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>

                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF
                          </p>
                        </div>
                        <input
                          onChange={props.handleChange}
                          type="file"
                          className="custom-file-input block w-full font-body text-sm text-sub cursor-pointer absolute h-full color-transparent -indent-56 focus:border-gogreen focus:ring-gogreen"
                          id="inputGroupFile05"
                          aria-describedby="inputGroupFileAddon05"
                          accept="image/x-png,image/gif,image/jpeg"
                          name="financialImg"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="st-2">
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Details
              </h3>
              <div className="image-details">
                <div className="input-group">
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Title
                  </label>
                  <input
                    className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    type="text"
                    name="financial_topText"
                    placeholder="Enter the video title here"
                    value={financial_topText}
                    onChange={props.handleChange}
                  />
                  <select
                    defaultValue={"medium"}
                    onChange={props.handleChange}
                    className="w-2/5 border-border mb-4 cursor-pointer text-sm focus:border-gogreen focus:ring-gogreen"
                    id="demo-simple-select"
                    label="Set Font Size"
                    name="topTextSize"
                  >
                    <option disabled={true} value="DEFAULT">
                      Set Font Size
                    </option>
                    <option value="small">
                      {financialOverlay === "overlay1" ? "110px" : "80px"} |
                      Small
                    </option>
                    <option value="medium">
                      {financialOverlay === "overlay1" ? "120px" : "90px"} |
                      Medium
                    </option>
                    <option value="large">
                      {financialOverlay === "overlay1" ? "130px" : "100px"} |
                      Large
                    </option>
                  </select>
                </div>
                <div className="input-group">
                  <label className="font-body text-sm font-normal text-sub w-full block mb-1">
                    Author
                  </label>
                  <select
                    defaultValue={"mikeHuang"}
                    onChange={props.handleChange}
                    className="w-2/5 border-border mb-4 cursor-pointer text-sm focus:border-gogreen focus:ring-gogreen"
                    id="demo-simple-select"
                    label="Set Font Size"
                    name="financialAuthor"
                  >
                    <option disabled={true} value="DEFAULT">
                      Select your Author
                    </option>

                    <option value="mikeHuang">Mike Huang</option>
                    <option value="jackyWang">Jacky Wang</option>
                    <option value="yoyoMa">Yoyo Ma</option>
                    <option value="michelleChen">Michelle Chen</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-span-7 sm:pl-0 lg:pl-11">
          <div
            id="gen-img-preview"
            className={`financial-analysis relative DisplayImg DisplayImgPreview ${financialOverlay} hidden sm:hidden lg:block st-3 bg-[#091F2C]`}
          >
            {financialOverlay === "overlay1" && (
              <img
                src="/go-logo.svg"
                alt="overlay1_logo"
                className="gomarkets-logo"
                width="295px"
              />
            )}
            {financialOverlay === "overlay2" && (
              <img
                src="/go-logo.svg"
                alt="overlay2_logo"
                className="gomarkets-logo"
                width="442px"
              />
            )}
            <div className="badge font-zh">每日财经点评</div>
            <div className="w-full h-full financialCont">
              {financialImg && (
                <img
                  src={financialImg}
                  className="z-0 img-fluid absolute financial-img-thumbnail"
                  alt="financial"
                />
              )}

              <div id="right" className="relative">
                <img
                  src={author.image}
                  className="z-10 absolute img-fluid bottom-0"
                  alt="financial"
                />
              </div>
            </div>

            {financial_topText ? (
              <h2 className={`topText ${fontStyle} ${props.data.topTextSize}`}>
                {financial_topText}
              </h2>
            ) : (
              <h2 className={`topText ${props.data.topTextSize}`}>
                Enter the video title here
              </h2>
            )}

            <div className="mx-[35px] flex items-center bottomTextContainer">
              {financialAuthor && (
                <span className={`bottomText ml-[18.9px]`}>
                  <div className="d-block">
                    <div className="font-gomaBold text-[38px]">
                      {author.name}
                    </div>
                    <div className="font-goma">
                      {author.title}{" "}
                      <span className="font-zh">{author.subTitle}</span>
                    </div>
                  </div>
                </span>
              )}
            </div>
          </div>

          {/* Generated component */}
          <div className="sr-only">
            <div
              id="gen-img"
              className={`financial-analysis DisplayImg ${financialOverlay} st-3 bg-[#091F2C] `}
            >
              {financialOverlay === "overlay1" && (
                <img
                  src="/GO_Logo_2.png"
                  alt="overlay1_logo"
                  className="gomarkets-logo"
                  width="590"
                />
              )}
              {financialOverlay === "overlay2" && (
                <img
                  src="/GO_Logo_2.png"
                  alt="overlay2_logo"
                  className="gomarkets-logo"
                  width="422"
                />
              )}
              <div className="badge font-zh">每日财经点评</div>
              <div className="w-full h-full financialCont">
                {financialImg && (
                  <img
                    src={financialImg}
                    className="z-0 img-fluid absolute financial-img-thumbnail"
                    alt="financial"
                  />
                )}
                <div id="right" className="relative">
                  <img
                    src={author.image}
                    className="z-10 absolute img-fluid bottom-0"
                    alt="financial"
                  />
                </div>
              </div>

              {financial_topText ? (
                <h2
                  className={`topText ${fontStyle} ${props.data.topTextSize}`}
                >
                  {financial_topText}
                </h2>
              ) : (
                <h2 className={`topText ${props.data.topTextSize}`}>
                  Enter the video title here
                </h2>
              )}

              <div className="mx-[56px] flex items-center bottomTextContainer">
                {financialAuthor && (
                  <span
                    className={`bottomText max-w-[1180px] ml-[70px] ${btmFontStyle}`}
                  >
                    <span className="d-block">
                      <div
                        className={`font-montserrat ${
                          financialOverlay === "overlay1"
                            ? "text-[80px]"
                            : "text-[60px]"
                        }`}
                      >
                        {author.name}
                      </div>
                      <div
                        className={`font-goma ${
                          financialOverlay === "overlay2"
                            ? "text-[40px]"
                            : "mt-[30px]"
                        }`}
                      >
                        {author.title}
                        <span
                          className={`"font-zh ml-2 ${
                            financialOverlay === "overlay2"
                              ? "!text-[40px]"
                              : ""
                          }`}
                        >
                          {author.subTitle}
                        </span>
                      </div>
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* End of generated component */}
          <div className="button-wrapper">
            <button
              type="button"
              className="btn mt-11 rounded st-4"
              onClick={(e) => {
                setSelected();
                props.handleFormReset(e);
              }}
            >
              Reset Preview
            </button>
            <button
              className="btn btn-primary ease-linear transition-all duration-150 rounded"
              type="button"
              onClick={props.handleJpeg}
            >
              Generate Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Financial;
