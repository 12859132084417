export const CustomSelect = ({ handleChange, name, id, label, obj }) => {
  return (
    <select
      defaultValue={"DEFAULT"}
      onChange={handleChange}
      className="form-control mb-2 w-22 text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
      id={id}
      label={label}
      name={name}
    >
      <option disabled={true} value="DEFAULT">
        Select {label}
      </option>
      {obj &&
        obj.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          );
        })}
    </select>
  );
};
