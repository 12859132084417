import React from "react";
import { useState } from "react";
import { ImageSizeSelector } from "../../../layouts/imageSizeSelector";

const initialState = {
  id: 1,
  name: "Default",
  image: "/1200x628-branding-def.jpg",
};

const images = [
  {
    id: 1,
    name: "Default",
    image: "/1200x628-branding-def.jpg",
  },
  {
    id: 2,
    name: "Default",
    image: "/1080x1080-branding-def.jpg",
  },
];

function Branding(props) {
  const [selected, setSelected] = useState(images[0]);
  const overlayType = props.data.brandingoverlay;

  return (
    <div>
      <div className="grid grid-cols-12 gap-8 sm:px-4">
        <div className="col-span-12 sm:col-span-12 lg:col-span-5">
          <p className="mb-6">
            Create and customise your image by using the fields below.
          </p>

          <form>
            <div>
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Settings
              </h3>

              <div className="image-settings">
                <div className="flex justify-left mb-7">
                  <ImageSizeSelector
                    handleChange={props.handleChange}
                    sizeObj={[
                      {
                        default: true,
                        sizeLabel: "1200x628",
                      },
                      {
                        default: false,
                        sizeLabel: "1080x1080",
                      },
                    ]}
                    name="brandingoverlay"
                  />
                </div>
                <div className="input-group">
                  <div className="custom-file relative">
                    <div className="max-w-xl">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col justify-center items-center w-full h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="mb-3 w-10 h-10 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>

                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            780x780px for best results
                          </p>
                        </div>
                        <input type="hidden" value={selected} />
                        <input
                          onChange={props.handleUploadbranding}
                          type="file"
                          className="custom-file-input block w-full font-body text-sm text-sub cursor-pointer absolute h-full color-transparent -indent-56 focus:border-gogreen focus:ring-gogreen"
                          id="inputGroupFile04"
                          aria-describedby="inputGroupFileAddon04"
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-span-7 sm:pl-0 lg:pl-11 ">
          <div
            id="gen-img-preview"
            className={`branding DisplayImg DisplayImgPreview ${props.data.brandingoverlay} hidden sm:hidden lg:block st-3`}
          >
            {overlayType === "overlay1" && (
              <img
                src={props.data.brandingImg}
                className="img-fluid img-thumbnail object-cover"
                alt=""
                width="100%"
              />
            )}
            {overlayType === "overlay2" && (
              <img
                src={props.data.brandingImg2}
                className="img-fluid img-thumbnail"
                alt=""
                width="100%"
              />
            )}

            <div
              id="bottom"
              className="flex relative justify-start items-center"
            >
              <img
                src="/go-logo-reverse@2x.png"
                className="ml-[25.95px]"
                alt="go-logo"
                width="212.85px"
              />
            </div>
          </div>

          {/* Generated component */}
          <div className="sr-only">
            <div
              id="gen-img"
              className={`branding DisplayImg ${props.data.brandingoverlay} lg:block`}
            >
              {overlayType === "overlay1" && (
                <img
                  src={props.data.brandingImg}
                  className="img-fluid img-thumbnail"
                  alt=""
                  width="1200"
                  height="628"
                />
              )}
              {overlayType === "overlay2" && (
                <img
                  src={props.data.brandingImg2}
                  className="img-fluid img-thumbnail"
                  alt=""
                  width="1080"
                  height="1080"
                />
              )}

              <div
                id="bottom"
                className="flex relative justify-start items-center"
              >
                <img
                  src="/go-logo-reverse@2x.png"
                  className="ml-[54px]"
                  alt="go-logo"
                  width="510px"
                />
              </div>
            </div>
          </div>
          {/* End of generated component */}
          <div className="button-wrapper">
            <button
              type="button"
              className="btn mt-11 rounded st-4"
              onClick={(e) => {
                setSelected(initialState);
                props.handleFormReset(e);
              }}
            >
              Reset Preview
            </button>
            <button
              className="btn btn-primary ease-linear transition-all duration-150 rounded"
              type="button"
              onClick={props.handleJpeg}
            >
              Generate Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Branding;
