import React from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Walkthrough from "../../walkthrough/walkthrough";
import {
  Back,
  Last,
  Next,
  Skip,
  Title,
  ValidateRun,
} from "../../walkthrough/settings";

import { LanguagePanel } from "../../../layouts/imgGenerator/language-panel";
import {
  DetectLanguage,
  TranslateInput,
} from "../../../services/firebaseActions/language";
import { LanguageFont } from "../../../utilities/utilityService";
import Preview from "./preview";
import { images, imageSizes } from "../../../utilities/imgGenerator/emailObjs";
import { ImageSizeSelector } from "../../../layouts/imageSizeSelector";

const initialState = {
  id: 1,
  name: "Metatrader",
  image: "/650-metatrader.jpg",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function EmailImgDisplay(props) {
  const [selected, setSelected] = useState(images[0]);
  const overlayType = props.data.overlay;
  const topText = props.data.topText;
  const hasImage = props.data.randomImg;
  const [checked, setChecked] = useState(false);

  // console.log(props.data);

  //translator
  const translateTop = TranslateInput(topText, "en", props.data.lang);
  //detector
  const detectLang = DetectLanguage(topText);
  //font
  const { fontStyle } = LanguageFont(detectLang);

  return (
    <div>
      <Walkthrough
        run={ValidateRun("emailTutorial")}
        tutorialStatus={"emailTutorial"}
        walkthSteps={[
          {
            disableBeacon: true,
            target: ".st-1",
            title: <Title>Template Type</Title>,
            offset: -10,
            content:
              "Select what template would like to use. We have templates that have different styles, eidting options to suit your purpose.",
            locale: {
              next: <Next />,
              skip: <Skip />,
            },
          },
          {
            disableBeacon: true,
            title: <Title>Asset Settings</Title>,
            offset: -10,
            target: ".st-2",
            content:
              "Use any number of optional fields or items to customise your asset.",
            locale: {
              back: <Back />,
              last: <Last />,
            },
          },
          {
            disableBeacon: true,
            target: ".st-3",
            offset: -50,
            title: <Title>Asset Preview</Title>,
            content: "See how your tailored asset will look as you build it",
            locale: {
              next: <Next />,
              skip: <Skip />,
            },
          },

          {
            disableBeacon: true,
            target: ".st-4",
            title: <Title>Ready to go?</Title>,
            content:
              "When your are happy with how it looks, download your tailored asset or reset the fields to start again.",
            locale: {
              back: <Back />,
              last: <Last />,
            },
          },
        ]}
      >
        <div className="email container mx-auto px-2 sm:px-4 lg:px-12 email">
          <div className="grid grid-cols-12 sm:px-4">
            <div className="sm:col-span-8 col-span-12">
              <div className="formselect">
                <select
                  onChange={props.handleChange}
                  name="forms"
                  className="w-96 st-1 mb-12 bg-transparent text-main font-display text-2xl font-bold border-transparent cursor-pointer pl-0 focus:border-gogreen focus:ring-gogreen"
                  id="demo-simple-select"
                  label="Select Image Creator Template"
                >
                  <option disabled={true} value="">
                    Select Image Creator Template
                  </option>
                  <option value="template1">Header Image</option>
                </select>
              </div>
            </div>
            {/*   <div className="hidden sm:flex sm:justify-end col-span-12 sm:col-span-4">
              <TutorialModal url={"./email-header-tut.mp4"} />
            </div> */}
          </div>
          <div className="grid grid-cols-12 gap-8 sm:px-4">
            <div className="col-span-12 sm:col-span-12 lg:col-span-4">
              <p className="mb-6">
                Create and customise your image by using the fields below.
              </p>
              <form>
                <h3 className="st-2 font-display text-lg font-bold text-sub mb-7">
                  Image Settings
                </h3>
                <div className="image-settings">
                  <div className="flex justify-left">
                    <ImageSizeSelector
                      handleChange={props.handleChange}
                      sizeObj={imageSizes}
                      name="ecoOverlay"
                    />
                  </div>
                  <div className="input-group my-8">
                    <div className="form-group">
                      <Listbox
                        value={selected}
                        onChange={(e) => {
                          setSelected(e);
                          props.handleSelect(e);
                        }}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="font-body text-sm font-normal text-sub text-muted mb-3">
                              Select Key Visual
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full cursor-pointer border border-border bg-white py-2 pl-3 pr-10 text-left focus:border-gogreen focus:outline-none focus:ring-1 focus:ring-gogreen sm:text-sm">
                                <span className="flex items-center cursor-pointer">
                                  <img
                                    src={selected.image}
                                    alt=""
                                    className="h-6 w-6 flex-shrink-0"
                                  />
                                  <span className="ml-3 block truncate text-sm">
                                    {selected.name}
                                  </span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="grid grid-cols-3 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {[...images].reverse().map((imageitem) => (
                                    <Listbox.Option
                                      key={imageitem.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? "text-white" : "text-sub",
                                          "relative cursor-pointer select-none py-2 pl-3 pr-9 w-full"
                                        )
                                      }
                                      value={imageitem}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className="block">
                                            {imageitem.image ? (
                                              <img
                                                src={imageitem.image}
                                                alt=""
                                                className="w-auto flex-shrink-0"
                                              />
                                            ) : (
                                              <div className="bg-main w-auto h-[71px] flex-shrink-0"></div>
                                            )}
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "text-sub text-xs mt-2 block truncate"
                                              )}
                                            >
                                              {imageitem.name}
                                            </span>
                                          </div>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-gogreen"
                                                  : "text-gogreen",
                                                "absolute inset-y-0 top-3 left-4 flex items-top pr-4 z-50"
                                              )}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                              </svg>
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                  <p className="font-body text-sm font-normal text-sub text-muted mb-3">
                    Or Upload Key Visual{" "}
                    <span className="italic text-xs">
                      (650px x 400px for best results.)
                    </span>
                  </p>
                  <div className="input-group">
                    <div className="custom-file relative">
                      <div className="max-w-xl">
                        <label className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                          <span className="flex items-center space-x-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-gray-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <span className="font-body text-sm font-normal text-sub">
                              Drop files to Attach, or
                              <span className="font-body text-sm font-normal text-gogreen underline">
                                {" "}
                                browse
                              </span>
                            </span>
                          </span>
                          <input
                            onChange={props.handleChange}
                            type="file"
                            className="custom-file-input block w-full font-body text-sm text-sub cursor-pointer absolute h-full color-transparent -indent-56 focus:border-gogreen focus:ring-gogreen"
                            id="inputGroupFile04"
                            aria-describedby="inputGroupFileAddon04"
                            accept="image/x-png,image/gif,image/jpeg"
                            name="randomImg"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="font-display text-lg font-bold text-sub mb-7">
                  Image Details
                </h3>
                <div className="image-details">
                  <div className="input-group inline-block w-full">
                    <label
                      htmlFor="checked-checkbox"
                      className="inline-block float-left mb-7 font-body text-sm font-normal text-sub italic"
                    >
                      Do you want content on your image?
                    </label>
                    <input
                      id="checked-checkbox"
                      type="checkbox"
                      className="cursor-pointer inline-block float-right w-4 h-4 text-gogreen bg-white-100 border-border focus:ring-gogreen dark:focus:ring-gogreen dark:ring-offset-gogreen focus:ring-2 dark:bg-gogreen dark:border-gogreen"
                      //checked={checked}
                      onChange={() => {
                        setChecked(!checked);
                      }}
                    />
                  </div>
                  <div
                    className={`input-group ${
                      !checked ? "w-full max-w-md space-y-2 z-5 blur" : ""
                    }`}
                  >
                    <div
                      className="bg-[#F7F8FA] border-[#95A7B8] border-solid border rounded-lg py-5 px-6 mb-3 font-body font-normal text-[#FEC533] inline-flex w-full"
                      role="alert"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="exclamation-triangle"
                        className="inline-block align-middle w-9 h-7 mr-4 mt-1 fill-current"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                        ></path>
                      </svg>
                      <div className="inline-block float-right">
                        <h3 className="text-sub text-sm font-bold mb-1">
                          Best Practice
                        </h3>
                        <p className="text-sub text-xs">
                          Consider how your title will look on mobile and try to
                          keep it short and scanable
                        </p>
                      </div>
                    </div>
                    <label className="font-body text-sm font-normal text-sub w-full">
                      Title
                    </label>
                    <input
                      className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                      type="text"
                      name="topText"
                      placeholder="Enter your campaign message here"
                      value={props.data.topText}
                      onChange={props.handleChange}
                      disabled={checked ? false : true}
                    />
                    <select
                      defaultValue={"large"}
                      onChange={props.handleChange}
                      className="w-2/5 border-border mb-4 cursor-pointer text-sm focus:border-gogreen focus:ring-gogreen"
                      id="demo-simple-select"
                      label="Set Font Size"
                      disabled={checked ? false : true}
                      name="topTextSize"
                    >
                      <option disabled={true} value="DEFAULT">
                        Set Font Size
                      </option>
                      <option value="small">42px | Small</option>
                      <option value="medium">48px | Medium</option>
                      <option value="large">52px | Large</option>
                    </select>
                  </div>
                </div>
                <LanguagePanel
                  topText={topText}
                  translateTop={translateTop}
                  handleLanguage={props.handleChange}
                  topTextPlaceHolder="Enter your campaign title here"
                />
              </form>
            </div>
            <div className="col-span-8 sm:pl-0 lg:pl-11">
              <Preview
                randomImg={props.data.randomImg}
                topTextSize={props.data.topTextSize}
                topText={props.data.topText}
                overlayType={overlayType}
                hasImage={hasImage}
                topTxt={topText}
                fontStyle={fontStyle}
              />
              <div className="flex w-max">
                <button
                  type="button"
                  className="btn mt-11 rounded bg-white"
                  onClick={(e) => {
                    setSelected(initialState);
                    props.handleFormReset(e);
                  }}
                >
                  Reset Preview
                </button>
                <button
                  type="button"
                  className="btn mt-11 btn-primary rounded st-4"
                  onClick={props.handleJpeg}
                >
                  Download Image
                </button>
              </div>
            </div>
          </div>
        </div>
      </Walkthrough>
    </div>
  );
}

export default EmailImgDisplay;
