import Cookies from "js-cookie";
import { getAuth } from "firebase/auth";

/** Refresh and replace ID token if current expired. **/
const RefreshAuth = (user) => {
  const auth = getAuth();
  const currUser = auth.currentUser;

  user.getIdToken().then(function (data) {
    localStorage.setItem("userName", currUser.displayName);
    localStorage.setItem("userEmail", currUser.email);

    if (
      parseFloat(currUser.metadata.lastLoginAt) -
        parseFloat(currUser.metadata.createdAt) <=
      1200000
    ) {
      localStorage.setItem("newUser", true);
    } else {
      localStorage.setItem("newUser", false);
    }

    if (data !== Cookies.get("token")) {
      Cookies.set(
        "token",
        data,
        process.env.NODE_ENV === "production" && {
          secure: true,
          sameSite: "strict",
        }
      );
    }
    if (currUser.uid !== Cookies.get("userId")) {
      Cookies.set(
        "userId",
        user.uid,
        process.env.NODE_ENV === "production" && {
          secure: true,
          sameSite: "strict",
        }
      );
    }
    if (currUser.photoURL !== Cookies.get("profImg")) {
      Cookies.set(
        "profImg",
        user.photoURL,
        process.env.NODE_ENV === "production" && {
          secure: true,
          sameSite: "strict",
        }
      );
    }
  });
};

export default RefreshAuth;
