export const images = [
  {
    id: 1,
    name: "Default",
    image: "/go-default.jpeg",
  },
  {
    id: 2,
    name: "Price tickers",
    image: "/price-tickers.jpeg",
  },
  {
    id: 3,
    name: "City",
    image: "/shanghai.jpeg",
  },
  {
    id: 4,
    name: "Gold",
    image: "/gold-bullion.jpeg",
  },
  {
    id: 5,
    name: "US Dollar",
    image: "/us-dollar.jpeg",
  },
  {
    id: 6,
    name: "Currency Prices",
    image: "/currency-prices.jpeg",
  },
  {
    id: 7,
    name: "Wharf",
    image: "/780x780-share-wharf.jpg",
  },
  {
    id: 8,
    name: "Globe",
    image: "/780x780-aus-globe.jpg",
  },
  {
    id: 9,
    name: "Bitcoin",
    image: "/780x780-bitcoin-1.jpg",
  },
  {
    id: 10,
    name: "Charts",
    image: "/780x780-charts-2.jpg",
  },
  {
    id: 11,
    name: "Energy",
    image: "/780x780-energy-1.jpg",
  },
  {
    id: 12,
    name: "Symbols",
    image: "/780x780-fx-symbols.jpg",
  },
  {
    id: 13,
    name: "Ticker",
    image: "/780x780-fx-ticker.jpg",
  },
  {
    id: 14,
    name: "Market",
    image: "/780x780-market-vola.jpg",
  },
  {
    id: 15,
    name: "Oil",
    image: "/780x780-oil-1.jpg",
  },
  {
    id: 16,
    name: "Pound",
    image: "/780x780-pound.jpg",
  },
  {
    id: 17,
    name: "Wall Street",
    image: "/780x780-wall-street.jpg",
  },
];

export const presenters = [
  {
    id: 0,
    name: "Default",
    image: "/profile.png",
  },
  {
    id: 1,
    name: "Lachlan",
    image: "/presenters/lachlan-meakin-400x400.jpeg",
  },
  {
    id: 2,
    name: "Mike",
    image: "/presenters/400x400-MS-profile.jpg",
  },
  {
    id: 3,
    name: "Evan",
    image: "/presenters/400x400-Evan-Lucas.jpg",
  },
  {
    id: 4,
    name: "Tanraj",
    image: "/presenters/tanraj-bains-400x400.png",
  },
  {
    id: 5,
    name: "Ryan",
    image: "/presenters/ryan-boyd-400x400.png",
  },
  {
    id: 6,
    name: "Klavs",
    image: "/presenters/klavs-valters-400x400.png",
  },
];

export const imageSizes = [
  {
    default: true,
    sizeLabel: "1200x628",
  },
  {
    default: false,
    sizeLabel: "1080x1080",
  },
  {
    default: false,
    sizeLabel: "1080x1920",
  },
  {
    default: false,
    sizeLabel: "1300x900",
  },
];
